/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/alpinejs@3.14.9/dist/cdn.min.js
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/plyr@3.6.12/dist/plyr.polyfilled.min.js
 * - /npm/swiper@7.0.9/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
